export function Accessibility() {
    return (
        <div className='home' style={{ padding: '10px' }}>
            <h1>הצהרת נגישות באתר</h1>
            <p>- pikmeTV שמה דגש למתן השירות לכלל הלקוחות לרבות אנשים בעלי מוגבלויות שונות.</p>
            <p>- אנו עושים מאמצים רבים כדי להנגיש כמה שיותר עמודים ולהטמיע תהליכים כדי שהנגישות תהיה זמינה ואיכותית.</p>
            <p>- בחלקו התחתון של האתר מופיע תפריט נגישות עם מגוון פיצ'רים על מנת להקל את חווית השימוש באתר עבור בעלי מוגבלויות.</p>
            <p>- תמיכה בכפתור טאב לשימוש באתר ללא עכבר.</p>
            <p>- מקש Ctrl + יגדיל את הטקסט באתר</p>
            <p>- מקש Ctrl – יקטין את הטקסט באתר</p>
            <p>- מקש Ctrl 0 יחזיר את האתר לגדלו המקורי</p>
            <p>- מקש רווח (SPACE) יוריד את האתר כלפי מטה</p>
            <p>- מקש F11 יגדיל את המסך לגודל מלא - לחיצה נוספת תקטין אותו חזרה</p>
            <p>- קיימת הפרדה מלאה בין תוכן לתצוגה ע''י שימוש בקובץ CSS נפרד</p>
            <p>- מקש F11 יגדיל את המסך לגודל מלא - לחיצה נוספת תקטין אותו חזרה</p>
            <p>- כתיבת קוד סמנטי עם כותרות בכל עמוד</p>
            <p>פרטי רכז הנגישות : שובל אליעזר | 0509620963 | homericentertainment@gmail.com</p>
        </div>
    )
}