import { useDispatch } from 'react-redux'
import { setPopup } from '../../store/actions/general.actions'
import { addSubscription } from '../../store/reducers/userReducer'
import { userService } from '../../services/userService'

export function Premium({ creator, user }) {
    const dispatch = useDispatch()

    const onPurchase = async () => {
        if (!user.phone) return dispatch(setPopup('login'))
        try {
            // to do: insert purchase logic here
            const creatorId = await userService.purchaseSubscription(creator._id)
            dispatch(addSubscription(creatorId))
        }
        catch (err) {
            console.log(err)
            dispatch(setPopup('errorServer'))
        }
    }

    return <div className="premium">
        <h1>רכוש מנוי לתוכן של {creator.nickName}</h1>
        <p>{creator.subscription?.description}</p>
        <button onClick={onPurchase}>{creator.subscription?.price}</button>
    </div>
}