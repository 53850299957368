import { useNavigate } from 'react-router'
import { formatDateHour } from '../services/utils'

export function ShowCard({ show }) {
    const navigate = useNavigate()
    return <div className="show-card" onClick={() => navigate(`/show/${show._id}`)}>
        <img src={show.img} alt='show' />
        <p className='title'>{show.title}</p>
        <div>
            <p>{show.ticketsAmount} צופים</p>
            <p>{formatDateHour(show.date)}</p>
        </div>

    </div>
}