import { useState } from "react"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setViewers } from '../store/actions/general.actions'
import { io } from "socket.io-client"

const socket = process.env.NODE_ENV === 'production' ? io.connect('https://server.pikme.tv') : io.connect('http://localhost:3030')

const colors = ['#FF5733', '#FFD700', '#00FF00', '#FF00FF', '#00FFFF', '#FF6347', '#FFD700', '#32CD32', '#DA70D6', '#87CEEB', '#FF4500', '#FF8C00', '#7CFC00', '#9400D3', '#00FF7F', '#FF1493', '#00BFFF', '#FFA07A', '#FFD700', '#00FF00', '#FF00FF', '#00FFFF', '#FF6347', '#FFD700', '#32CD32', '#DA70D6', '#87CEEB', '#FF4500', '#FF8C00'];

export function StreamChat({ showId, zIndex, setStream, setFullScreen, setPopup, user, setAlreadyIn, loadStreamUrl }) {
    const dispatch = useDispatch()
    const [messages, setMessages] = useState([])
    let nickName = user.nickName
    if (!nickName) nickName = "Guest" + Math.floor(Math.random() * 100000)
    let randomColor = colors[nickName.charCodeAt(0) % colors.length] || 'white'

    useEffect(() => {
        if (socket && user) socket.emit('joinRoom', { username: user.nickName, roomName: showId, userId: user.phone })
        socket.on('event-ended', () => {
            setStream('over')
            setFullScreen(false)
            setPopup(true)
        })
        socket.on('alreadyIn', () => { console.log('alreadyIn'); setAlreadyIn(true) })
        socket.on('event-started', loadStreamUrl)
        return () => {
            socket.off("message")
            socket.off("event-ended")
            socket.off("alreadyIn")
        }
    }, [])

    useEffect(() => {
        document.getElementById('body-text').scrollTop = document.getElementById('body-text').scrollHeight
    }, [messages])

    

    socket.on('message', (message) => {
        if (message.viewers) dispatch(setViewers(message.viewers))
        if (message.newRoom) setMessages([message])
        else setMessages([...messages, message])
    })

    const onEnter = (ev) => {
        if (ev.key === "Enter") sendMessage()
    }

    const sendMessage = () => {
        const message = document.getElementById('input').value
        if (message === "") return
        if (socket) socket.emit('chat', { "nickName": nickName, "message": message, "color": randomColor, roomId: showId })
        document.getElementById('input').value = ''
        document.getElementById('input').focus()
        document.getElementById('body-text').scrollTop = document.getElementById('body-text').scrollHeight
    }

    const prevent = (e) => {
        e.preventDefault()
    }

    return (
        <div className="chat-box" style={{ zIndex }}>
            <div className="chat-header">
                <img src={require('../style/imgs/stream/chat-header.webp')} alt='chat' />
                <h1>צ'אט</h1>
                <div className="scrollers" style={{ width: '20px' }} />
            </div>
            <div id="body-text" className="body-text">
                {messages.map((message, index) => {
                    return (
                        <div key={index} className="message-div rtl align-right">
                            <div>
                                <span style={{ color: message.color }}> {message.nickName} : </span>
                                <span >{message.message}</span>
                            </div>
                        </div>)
                })}
            </div>
            <form className="chat-box-input" onSubmit={prevent}>
                <input id="input" type="text" onKeyDown={onEnter} placeholder="הקלד" autoComplete="off" onClick={() => setTimeout(() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }), 500)} />
                <button className="noselect ltr"><span className="material-symbols-outlined mirror" onClick={sendMessage}>send</span></button>
            </form>
        </div>
    )
}