import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useLocation } from "react-router-dom"
import { SearchBar } from "./search-bar"
import { setFilter } from '../store/actions/general.actions'
import { toggleMenu } from "../store/actions/general.actions"
import { svgService } from "../services/svgService"

export function Header() {
    const dispatch = useDispatch()
    const location = useLocation()
    const [headerMode, setHeaderMode] = useState('')
    const [isSearch, setIsSearch] = useState(false)
    const user = useSelector((state) => state.user)
    const { animations } = useSelector((storeState) => storeState.generalModule)
    const { flashUpcoming } = useSelector((storeState) => storeState.generalModule)

    let isMobile = window.innerWidth < 930 ? true : false

    const resetFilter = () => {
        dispatch(setFilter(''))
    }

    const onLogoClick = () => {
        resetFilter()
        if (location.pathname === '/') window.location.reload()
    }

    return <>
        {(!isMobile) && <nav className='header noselect'>
            <NavLink onClick={onLogoClick} className='undecorate hover-main' to='/' ><img id="logo" alt='pikmetv logo' className="logo" src={require('../style/imgs/logo.webp')} /></NavLink>
            {isSearch ? <> <div className="screen" onClick={() => { setIsSearch(false) }}>
            </div> <SearchBar addX={true} setIsSearch={setIsSearch} /> </> :
                <div className="search-placeholder" onClick={() => setIsSearch(true)}>
                    <button><img className="search-icon" src={require('../style/imgs/search-icon.webp')} alt='search' /></button>
                </div>}
            <div className="header-options-bar">
                <NavLink style={{ display: 'flex' }} to='/profile'> {(user && user.image) ? <img className='header-user-img' alt='profile' src={user.image} /> : svgService.userIcon()}</NavLink>
                <NavLink style={{ display: 'flex' }} to="/tickets" onClick={resetFilter}>{svgService.stream((flashUpcoming && animations), location.pathname.includes('/tickets'))}</NavLink>
                <span className="material-symbols-outlined menu-icon clickable hover-main" onClick={() => { dispatch(toggleMenu()) }} tabIndex={0}>menu</span>
            </div>
        </nav>}

        {(isMobile) && <>
            <nav className='header noselect'>
                {headerMode === 'search' ? <div className="mobile-search-wrapper">
                    <span onClick={() => { setHeaderMode('') }} className="material-symbols-outlined">arrow_back_ios_new</span>
                    <SearchBar />
                </div> : <>
                    <NavLink onClick={onLogoClick} to='/'><img alt='pikmetv logo' className="logo" src={require('../style/imgs/logo.webp')} /></NavLink>
                    <div className="header-mobile-bar">
                        <span className="material-symbols-outlined search-icon" onClick={() => setHeaderMode('search')}>search</span>
                        <span className="material-symbols-outlined menu-icon clickable hover-main" onClick={() => dispatch(toggleMenu())}>menu</span>
                    </div>
                </>}
            </nav>

            {!location.pathname.includes('/tickets') && <nav className='footer-mobile'>
                <NavLink style={{ color: 'white', textDecoration: 'none' }} onClick={resetFilter} to='/tickets'>{svgService.stream((flashUpcoming && animations), location.pathname.includes('/tickets'))}</NavLink>
                <NavLink onClick={onLogoClick} to='/' alt='stream'><img src={require(`../style/imgs/home-icon-dark.webp`)} alt='home' /></NavLink>
                <NavLink to='/profile'><img alt='user profile' className='user-img circle' src={(user && user.image) ? user.image : require('../style/imgs/user-icon.webp')} /></NavLink>
            </nav>}
        </>}
    </>
}