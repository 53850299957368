import { useNavigate } from "react-router"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { setFlashUpcoming } from "../store/actions/general.actions"
export function StreamPopup({ setPopup }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => dispatch(setFlashUpcoming(false)), [])

    return <section className="simple-popup popup-stream">
        <h1>ההופעה הסתיימה</h1>
        <div className="buttons-wrapper">
            <div onClick={() => { setPopup(false) }} className="lighter">סגור</div>
            <div className="bolder" onClick={() => { setPopup(false); navigate('/') }}>בית</div>
        </div>
    </section>
}