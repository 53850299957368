import { useRef } from 'react'
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setFilter } from '../store/actions/general.actions'

export function SearchBar(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const searchRef = useRef()

    const onSearch = (ev) => {
        ev.preventDefault()
        dispatch(setFilter(searchRef.current.value ))
        props.setIsSearch(false)
        navigate("/")
    }

    return (
        <form onSubmit={onSearch} className='search-bar'>
            <button><img className="search-icon" src={require('../style/imgs/search-icon.webp')} alt='search'/></button>
            <input type='text' ref={searchRef} placeholder='חפש הופעות' autoFocus />
            {props.addX && <span onClick={() => props.setIsSearch(false)} className="material-symbols-outlined clickable">close</span>}
        </form>
    )
}