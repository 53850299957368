import { useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { logout } from "../store/reducers/userReducer"
import { setUpperPopup, setPopup } from "../store/actions/general.actions"
import { setMenu } from "../store/actions/general.actions"
import { isMobile } from "react-device-detect"

const descs = [
    '',
    '',
    'PikmeTV היא פלטפורמה חדשה לצפייה בהופעות חיות ומופעים חיים בזמן אמת. כאן תוכלו למצוא הופעות של יוצרים ישראלים וגם יוצרים מהעולם. כל ההופעות מתרחשות בזמן אמת וניתן לצפות בהן מכל מקום.',
    '',
    'Homeric Entertainment LTD היא חברת הפיתוח שעומדת מאחורי הפלטפורמה. החברה נוסדה בשנת 2021 ומטרתה להביא את ההופעות החיות לכל אחד ואחת בצורה נוחה וקלה.',
    '',
    'בעמוד הבית מוצגות הופעות מאושרות על ידינו ושעתידות להתרחש בעתיד הקרוב. אם אינכם רואים הופעות, כנראה שיוצרי התוכן שאתם עוקבים אחריהם לא העלו משהו חדש. תמיד ניתן לעקוב אחר יוצרי תוכן נוספים שיוצגו מעט מתחת להופעות בעמוד הבית.',
    '',
    'לרשותכם שורת חיפוש ליד הלוגו בחלק העליון של המסך. ניתן לחפש הופעות או יוצרי תוכן.',
    '',
    'כרגע לא ניתן לצפות בשידורים חוזרים של הופעות בפלטפורמה.',
    '',
    'אם יוצר תוכן חסם אותך עקבות התנהגות לא הולמת, לא תוכל לקנות כרטיס להופעות חדשות של אותו יוצר תוכן. במידה ורכשת כרטיס להופעה לפני שחסם אתכם תוכלו להשתתף בה כרגיל. אם אתם מאמינים שחלה טעות תוכלו ליצור איתנו קשר ונברר את העניין.',
    '',
    'כמובן! אם הופעה בוטלה על ידי היוצר תקבלו החזר כספי באופן אוטומטי תוך 2 ימי עסקים.',
    '',
    'אם נתקלתם בבעיה ניתן לפתוח את התפריט בחלק העליון והימני במסך, ללחוץ על "תמיכה" ולמלא את הטופס. המענה האנושי שלנו יצור אכם קשר בהקדם.'
]

export function Menu() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [selectedFaq, setSelectedFaq] = useState({ opt: '', desc: '', order: 20 })
    const { menu } = useSelector((storeState) => storeState.generalModule)
    const user = useSelector((state) => state.user)

    const logOut = () => {
        try {
            dispatch(logout())
            dispatch(setUpperPopup('logout'))
            navigate('/')
        }
        catch {
            console.log('could not log out')
        }
    }

    const manageSelected = (opt) => {
        if (selectedFaq.opt === opt) {
            setSelectedFaq({ opt: '', desc: '', order: 20 })
            return
        }

        setSelectedFaq({ opt, desc: descs[opt + 1], order: opt + 1 })
    }

    if (menu === '') return <></>
    return <>
        <div className="screen" onClick={() => dispatch(setMenu(''))}></div>
        <section className='menu noselect'>
            {menu === 'normal' && <>
                <div className="hover-main" onClick={() => { dispatch(setMenu('')) }}><NavLink to='/profile'><span className="material-symbols-outlined">local_activity</span> <div>הכרטיסים שלי</div></NavLink></div>
                <div className="hover-main" onClick={() => dispatch(setMenu('faq'))}><span className="material-symbols-outlined">live_help</span> <div>שאלות נפוצות</div></div>
                <div className="hover-main" onClick={() => { dispatch(setPopup('support')); dispatch(setMenu('')) }}><span className="material-symbols-outlined">support_agent</span> <div>תמיכה</div></div>
                {user.nickName ? <div onClick={() => { logOut(); dispatch(setMenu('')) }} className="hover-main"><span className="material-symbols-outlined">logout</span> <div>התנתק</div></div> :
                    <div className="hover-main" onClick={() => { dispatch(setMenu('')) }}><NavLink className="main-color" to='/profile'><span className="material-symbols-outlined">login</span><div>התחבר</div></NavLink></div>}
                {isMobile && <div onClick={() => dispatch(setMenu(''))} className="close-mobile clickable"><span className="material-symbols-outlined">cancel</span></div>}
            </>}
            {menu === 'faq' && <>
                <div className="close" onClick={() => dispatch(setMenu('normal'))}><span className="material-symbols-outlined">close</span></div>
                <div className='center-start noselect'>
                    <p className="help-p">שאלות נפוצות</p>
                    <div style={{ order: 1 }} onClick={() => manageSelected(1)} className='help-opt hover-main'><p>מה זה PikmeTV ?</p>
                        <span className="material-symbols-outlined mirror">{selectedFaq.opt === 1 ? 'expand_more' : 'chevron_right'}</span></div>
                    <div style={{ order: 3 }} onClick={() => manageSelected(3)} className='help-opt hover-main'><p>מי עומד מאחורי PikmeTV ?</p>
                        <span className="material-symbols-outlined mirror">{selectedFaq.opt === 3 ? 'expand_more' : 'chevron_right'}</span></div>
                    <div style={{ order: 5 }} onClick={() => manageSelected(5)} className='help-opt hover-main'><p>למה אני לא רואה הופעות בעמוד הבית ?</p>
                        <span className="material-symbols-outlined mirror">{selectedFaq.opt === 5 ? 'expand_more' : 'chevron_right'}</span></div>
                    <div style={{ order: 7 }} onClick={() => manageSelected(7)} className='help-opt hover-main'><p>אני רוצה למצוא הופעה ספציפית, איך עושים את זה?</p>
                        <span className="material-symbols-outlined mirror">{selectedFaq.opt === 7 ? 'expand_more' : 'chevron_right'}</span></div>
                    <div style={{ order: 9 }} onClick={() => manageSelected(9)} className='help-opt hover-main'><p>האם ניתן לצפות בהופעות גם אחרי שנגמרו?</p>
                        <span className="material-symbols-outlined mirror">{selectedFaq.opt === 9 ? 'expand_more' : 'chevron_right'}</span></div>
                    <div style={{ order: 11 }} onClick={() => manageSelected(11)} className='help-opt hover-main'><p>יוצר תוכן חסם אותי, מה זה אומר?</p>
                        <span className="material-symbols-outlined mirror">{selectedFaq.opt === 11 ? 'expand_more' : 'chevron_right'}</span></div>
                    <div style={{ order: 13 }} onClick={() => manageSelected(13)} className='help-opt hover-main'><p>הופעה התבטלה, האם אני מקבל החזר כספי?</p>
                        <span className="material-symbols-outlined mirror">{selectedFaq.opt === 13 ? 'expand_more' : 'chevron_right'}</span></div>
                    <div style={{ order: 15 }} onClick={() => manageSelected(15)} className='help-opt hover-main'><p>קרתה לי בעיה. איך יוצרים קשר ?</p>
                        <span className="material-symbols-outlined mirror">{selectedFaq.opt === 15 ? 'expand_more' : 'chevron_right'}</span></div>


                    {selectedFaq.opt && <p className="ans" style={{ order: selectedFaq.order }}>{selectedFaq.desc}</p>}
                </div>
            </>}
        </section >
    </>
}

