import React, { useState, useRef } from "react"
import { useSelector } from "react-redux"
import { StreamChat } from "../cmps/stream-chat"
import { StreamPopup } from "../cmps/stream-popup"
import Timer from "../cmps/timer"
import { Error } from "./error"
import { useEffect } from "react"
import { httpService } from "../services/http.service"
const APP_ID = '2148ba0fc4934b56b78fc915f29945f1'
let channelParameters = { localAudioTrack: null, localVideoTrack: null, }

export function Stream({ show, user, client }) {
    const [stream, setStream] = useState('paused')
    const [fullScreen, setFullScreen] = useState(false)
    const [popup, setPopup] = useState(false)
    const [isMuted, setIsMuted] = useState(false)
    const [alreadyIn, setAlreadyIn] = useState(false)
    const [showstarted, setshowstarted] = useState(false)
    const { viewers } = useSelector((state) => state.generalModule)
    let reloadInterval = useRef(null)

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 })
        document.documentElement.style.setProperty('--visibility', 'hidden')
        return () => document.documentElement.style.setProperty('--visibility', 'visible')
    }, [])

    useEffect(() => {
        let now = new Date()
        let eventDate = new Date(show.date)
        if (eventDate < now) setshowstarted(true)
        else setshowstarted(false)
        init(show, false)
        return () => cleanup()
    }, [show])

    const cleanup = () => {
        if (channelParameters.localAudioTrack && channelParameters.localAudioTrack != "") {
            channelParameters.localAudioTrack.stop()
            channelParameters.localAudioTrack = ""
        }
        if (channelParameters.localVideoTrack && channelParameters.localVideoTrack != "") {
            channelParameters.localVideoTrack.stop()
            channelParameters.localVideoTrack = ""
        }
        client.remoteUsers.forEach(user => {
            client.unsubscribe(user)
        })
        client.removeAllListeners()
        client.leave()
    }

    const init = async (show, joined) => {
        let channelName1 = show._id
        let uid = String(Math.floor(Math.random() * 10000000))
        try {
            const token = await httpService.get(`handle-stream/${show._id}/${uid}`)
            if (joined) cleanup()
            await client.join(APP_ID, channelName1, token, uid)
            await client.setClientRole('audience')

            client.on("user-published", async (user, mediaType) => {
                await client.subscribe(user, mediaType)
                if (reloadInterval.current) clearInterval(reloadInterval.current)
                if (mediaType === "video") {
                    channelParameters.localVideoTrack = user.videoTrack
                    setStream('live')
                    channelParameters.localVideoTrack.play("agora_remote")
                    let element = document.getElementsByClassName("agora_video_player")[0]
                    element.controls = true
                }
                if (mediaType === "audio") {
                    channelParameters.localAudioTrack = user.audioTrack
                    channelParameters.localAudioTrack.play()
                }
            })

            client.on("user-unpublished", async (user, mediaType) => {
                await client.unsubscribe(user, mediaType)
                if (mediaType === "audio") {
                    if (channelParameters.localAudioTrack && channelParameters.localAudioTrack != "") {
                        channelParameters.localAudioTrack.stop()
                        channelParameters.localAudioTrack = ""
                        setStream('reconnect')
                        reloadInterval.current = setInterval(() => {
                            setStream('paused')
                        }, 5000)
                    }
                }
                if (mediaType === "video") {
                    if (channelParameters.localVideoTrack && channelParameters.localVideoTrack != "") {
                        channelParameters.localVideoTrack.stop()
                        channelParameters.localVideoTrack = ""
                    }
                }
            })
        }
        catch (e) {
            console.log("join failed", e)
        }
    }

    const getStreamInside = () => {
        if (stream === 'live') return <></>
        if (!showstarted) return <div className='over-video'> <section className="timer-wrapper">
            <img alt='live' className="live-in" src={require('../style/imgs/stream/live-in.webp')} />
            <Timer showDate={new Date(show.date)} onEndStream={setshowstarted} />
        </section></div>
        return <section className="over-video">
            {stream === 'over' && <h1>ההופעה הסתיימה</h1>}
            {stream === 'paused' && <>
                <h1>מחכים לתחילת ההופעה...</h1>
                <p className="cant-see">אם אינכם מסוגלים לראות את התוכן לחצו <span onClick={() => window.location.reload()}>כאן</span>.</p>
            </>}
            {stream === 'reconnect' && <div className="loader"><div></div><div></div><div></div><div></div>
                <div></div><div></div><div></div><div></div></div>}
        </section>
    }

    function muteVideo(mute) {
        let videoElement = document.getElementById('agora_remote')
        if (!videoElement) return
        if (mute) {
            videoElement.muted = true;
            videoElement.volume = 0;
            setIsMuted(true)
        }
        else {
            videoElement.muted = false;
            videoElement.volume = 1;
            setIsMuted(false)
        }
    }

    if (alreadyIn) return <div className='error'>
        <img src={require('../style/imgs/error.webp')} alt='error' />
        <h1>שגיאה</h1>
        <p>ניתן לצפות בהופעה ממכשיר אחד בלבד בו זמנית.</p>
        <p>אנא סגור את האתר בכרטיסיות/מכשירים אחרים ונסה שוב. וודא שלא שלחת לינק להופעה לאף אחד אחר.</p>
    </div>

    if (!show) return <div className="stream-container"><div className="home"><div className="loader"><div></div><div></div><div></div><div></div>
        <div></div><div></div><div></div><div></div></div></div></div>

    try {
        return <div className="stream-container">
            <div className="stream">
                <div className='stream-video'>
                    <div id='agora_remote' style={{ width: '100%', height: '100%' }} />
                    {getStreamInside()}
                </div>

                <div className="stream-info noselect">
                    <div className="chosen">
                        <img src={show.miniCreator.image} />
                        <div>
                            <h5>{show.title}</h5>
                        </div>
                    </div>
                    <div className="details details-fund" >
                        <div>
                            {isMuted ? <svg className='clickable sec-svg' onClick={() => muteVideo(false)} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path d="M22.7439 14.4978C22.7439 15.3792 22.597 16.2278 22.3282 17.0186L21.0561 15.7465C21.1394 15.3355 21.1813 14.9172 21.1812 14.4978V12.935C21.1812 12.7278 21.2635 12.5291 21.41 12.3825C21.5566 12.236 21.7553 12.1537 21.9626 12.1537C22.1698 12.1537 22.3685 12.236 22.5151 12.3825C22.6616 12.5291 22.7439 12.7278 22.7439 12.935V14.4978ZM14.9301 20.7489C16.2084 20.7489 17.3961 20.366 18.3869 19.7065L19.509 20.8302C18.3934 21.6392 17.0828 22.137 15.7115 22.2726V25.4372H20.3998C20.607 25.4372 20.8058 25.5196 20.9523 25.6661C21.0988 25.8126 21.1812 26.0114 21.1812 26.2186C21.1812 26.4258 21.0988 26.6246 20.9523 26.7711C20.8058 26.9177 20.607 27 20.3998 27H9.46037C9.25313 27 9.05439 26.9177 8.90785 26.7711C8.76131 26.6246 8.67898 26.4258 8.67898 26.2186C8.67898 26.0114 8.76131 25.8126 8.90785 25.6661C9.05439 25.5196 9.25313 25.4372 9.46037 25.4372H14.1487V22.2726C12.2213 22.0789 10.4346 21.1762 9.13513 19.7396C7.83569 18.303 7.11619 16.4349 7.11621 14.4978V12.935C7.11621 12.7278 7.19854 12.5291 7.34507 12.3825C7.49161 12.236 7.69036 12.1537 7.8976 12.1537C8.10483 12.1537 8.30358 12.236 8.45012 12.3825C8.59666 12.5291 8.67898 12.7278 8.67898 12.935V14.4978C8.67898 16.1557 9.33758 17.7457 10.5099 18.918C11.6822 20.0903 13.2722 20.7489 14.9301 20.7489ZM19.6184 6.68395V14.3087L18.0556 12.7459V6.68395C18.0592 5.86859 17.7441 5.08409 17.1774 4.49784C16.6107 3.91159 15.8374 3.56999 15.0223 3.54593C14.2073 3.52187 13.4152 3.81725 12.8149 4.36905C12.2146 4.92085 11.8537 5.68539 11.8092 6.49954L10.4887 5.179C10.8465 4.12763 11.5657 3.23734 12.5185 2.66663C13.4712 2.09592 14.5955 1.88183 15.6913 2.06246C16.7871 2.24309 17.7832 2.80673 18.5024 3.65303C19.2215 4.49933 19.617 5.57337 19.6184 6.68395Z"
                                    fill='white' fillOpacity="0.9" />
                                <path d="M17.2521 18.5722L16.0832 17.4032C15.6091 17.5914 15.0961 17.6608 14.589 17.6051C14.0819 17.5495 13.5962 17.3706 13.1742 17.0841C12.7521 16.7975 12.4067 16.412 12.1679 15.9613C11.9291 15.5105 11.8043 15.0081 11.8043 14.498V13.1259L10.2415 11.5631V14.498C10.2413 15.319 10.4566 16.1256 10.866 16.8372C11.2753 17.5488 11.8644 18.1404 12.5742 18.5529C13.284 18.9654 14.0896 19.1843 14.9106 19.1877C15.7315 19.191 16.539 18.9788 17.2521 18.5722ZM5 4.11181L23.7533 22.8651L24.8597 21.7586L6.10644 3.00537L5 4.11181Z"
                                    fill='white' fillOpacity="0.9" />
                            </svg> :
                                <svg className='clickable' onClick={() => muteVideo(true)} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.8 11.1001C1.01217 11.1001 1.21566 11.1844 1.36569 11.3344C1.51571 11.4844 1.6 11.6879 1.6 11.9001V13.5001C1.6 15.1975 2.27428 16.8253 3.47452 18.0256C4.67475 19.2258 6.30261 19.9001 8 19.9001C9.69739 19.9001 11.3253 19.2258 12.5255 18.0256C13.7257 16.8253 14.4 15.1975 14.4 13.5001V11.9001C14.4 11.6879 14.4843 11.4844 14.6343 11.3344C14.7843 11.1844 14.9878 11.1001 15.2 11.1001C15.4122 11.1001 15.6157 11.1844 15.7657 11.3344C15.9157 11.4844 16 11.6879 16 11.9001V13.5001C16 15.4833 15.2634 17.3959 13.933 18.8667C12.6026 20.3375 10.7733 21.2618 8.8 21.4601V24.7001H13.6C13.8122 24.7001 14.0157 24.7844 14.1657 24.9344C14.3157 25.0844 14.4 25.2879 14.4 25.5001C14.4 25.7123 14.3157 25.9158 14.1657 26.0658C14.0157 26.2158 13.8122 26.3001 13.6 26.3001H2.4C2.18783 26.3001 1.98434 26.2158 1.83431 26.0658C1.68429 25.9158 1.6 25.7123 1.6 25.5001C1.6 25.2879 1.68429 25.0844 1.83431 24.9344C1.98434 24.7844 2.18783 24.7001 2.4 24.7001H7.2V21.4601C5.2267 21.2618 3.3974 20.3375 2.06701 18.8667C0.736615 17.3959 -2.49147e-05 15.4833 6.3201e-10 13.5001V11.9001C6.3201e-10 11.6879 0.0842856 11.4844 0.234315 11.3344C0.384344 11.1844 0.587827 11.1001 0.8 11.1001Z" fill="#F3F3FF" />
                                    <path d="M11.2002 13.5002C11.2002 14.3489 10.8631 15.1628 10.2629 15.7629C9.66282 16.3631 8.84889 16.7002 8.0002 16.7002C7.1515 16.7002 6.33757 16.3631 5.73745 15.7629C5.13734 15.1628 4.8002 14.3489 4.8002 13.5002V5.5002C4.8002 4.6515 5.13734 3.83757 5.73745 3.23745C6.33757 2.63734 7.1515 2.3002 8.0002 2.3002C8.84889 2.3002 9.66282 2.63734 10.2629 3.23745C10.8631 3.83757 11.2002 4.6515 11.2002 5.5002V13.5002ZM8.0002 0.700195C6.72716 0.700195 5.50626 1.20591 4.60608 2.10608C3.70591 3.00626 3.2002 4.22716 3.2002 5.5002V13.5002C3.2002 14.7732 3.70591 15.9941 4.60608 16.8943C5.50626 17.7945 6.72716 18.3002 8.0002 18.3002C9.27323 18.3002 10.4941 17.7945 11.3943 16.8943C12.2945 15.9941 12.8002 14.7732 12.8002 13.5002V5.5002C12.8002 4.22716 12.2945 3.00626 11.3943 2.10608C10.4941 1.20591 9.27323 0.700195 8.0002 0.700195Z" fill="#F3F3FF" />
                                </svg>}
                            <img src={require('../style/imgs/stream/viewers.webp')} />
                            <p>{viewers}</p>
                        </div>
                    </div>
                </div>
            </div>
            {popup && <StreamPopup fullScreen={fullScreen} setPopup={setPopup} />}
            <StreamChat showId={show._id} setStream={setStream} setFullScreen={setFullScreen} setPopup={setPopup} user={user} setAlreadyIn={setAlreadyIn} />
        </div >
    }
    catch (err) {
        console.log(err)
        return <Error />
    }
}