import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { showService } from '../services/showService'
import { userService } from '../services/userService'
import { setFilter, setPopup, setPopupInfo, setUpperPopup } from '../store/actions/general.actions'
import { Error } from './error'
import { ErrorServer } from './error-server'
import { ShowBox } from '../cmps/show-box'
import { ShowBoxLoader } from '../cmps/show-box-loader'
import { Wallpaper } from '../cmps/wallpaper'
import { Discover } from '../cmps/discover'

export function Home({ setNewTimer }) {
    const dispatch = useDispatch()
    const [shows, setShows] = useState('loading')
    const [discover, setDiscover] = useState({ shows: [], creators: [] })
    const [error, setError] = useState(false)
    const { id } = useParams()
    const { filter } = useSelector((storeState) => storeState.generalModule)
    const user = useSelector((state) => state.user)

    useEffect(() => {
        loadFeed()
    }, [filter, id])

    const loadFeed = async () => {
        if (id) {
            try {
                const loadedShow = await showService.getById(id)
                if (new Date(loadedShow.date) < new Date()) setShows([])
                else setShows([loadedShow])
            }
            catch {
                dispatch(setFilter('dfgdfvbxc'))
                setShows([])
            }
            return
        }
        else {
            try {
                const { shows, discover } = await userService.getFeed(user.following, filter, Object.keys(user.purchases))
                setShows(shows)
                setDiscover(discover)
            }
            catch (err) {
                console.group(err)
                setError(true)
            }
        }
    }

    const showInfo = (show) => {
        dispatch(setPopupInfo(show))
        dispatch(setPopup('info'))
    }

    const share = (id) => {
        navigator.clipboard.writeText('https://pikme.tv/#/show/' + id)
        dispatch(setUpperPopup('copied'))
    }

    if (error) return <ErrorServer />

    try {
        return <div className='relative home'>
            <Wallpaper />
            <>
                {(shows === 'loading') && <div className='events-wrapper'><ShowBoxLoader /></div>}
                {(typeof shows === 'object' && shows.length > 0) && <div className='events-wrapper'>
                    {(shows.map((show, idx) =>
                        <section id={idx + 1} key={show._id} className='event-box-wrapper' >
                            <div className='event-box-upper' >
                                {show.longDescription && <div onClick={() => showInfo(show)}><span className="material-symbols-outlined">info_i</span></div>}
                                <div onClick={() => share(show._id)}><span className="material-symbols-outlined">share</span></div>
                                {id && <div onClick={() => window.history.back()}><span className="material-symbols-outlined">reply</span></div>}
                            </div>
                            <ShowBox show={show} setNewTimer={setNewTimer} user={user} />
                        </section>))}
                </div>}
                {(typeof shows === 'object' && shows.length === 0) && <div className="not-found" style={{ flex: filter ? 1 : 0, paddingTop: filter ? 0 : '30px' }}>
                    <img className="no-history" src={require('../style/imgs/no-results.webp')} alt='no results' />
                    {filter ? <>
                        <p>לא מצאנו תוצאות לחיפוש.</p>
                        <p onClick={() => dispatch(setFilter(''))} className='main-color clickable'>הסר חיפוש</p>
                    </>
                        :
                        <p>יוצרי התוכן שאתה עוקב אחריהם לא העלו שום דבר חדש. ניתן לגלות יוצרי תוכן והופעות אחרות :</p>}
                </div>}
                {(!filter && !id) && <Discover discover={discover} />}
            </>
        </div>
    }
    catch (err) {
        console.log(err)
        return <Error />
    }
}