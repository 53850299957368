import { useDispatch } from "react-redux"
import { setPopup, setPopupPay } from "../store/actions/general.actions"
import { formatDateHour } from "../services/utils"
import { Error } from "../pages/error"
import { NavLink } from "react-router-dom"

export function ProfileTable({ shows }) {
    const dispatch = useDispatch()

    try {
        return <section className='profile-history'>
            {shows.length === 0 && <div className="center no-history-wrapper">
                <img className="no-history" src={require('../style/imgs/no-results.webp')} alt='no history' />
                <p>עוד לא קנית כרטיס לאף הופעה.<NavLink className='main-color' to='/'> לקניית כרטיסים.</NavLink></p>
            </div>}

            {shows.length > 0 && <table >
                <thead>
                    <tr>
                        <td>שם</td>
                        <td>יוצר תוכן</td>
                        <td>מחיר</td>
                        <td>תאריך רכישה</td>
                        <td>תאריך אירוע</td>
                    </tr>
                </thead>
                <tbody>
                    {shows.map((show, idx) => {
                        return <tr key={idx}>
                            <td>{show.title}</td>
                            <td>{show.creatorName}</td>
                            <td>{show.price}₪</td>
                            <td> {formatDateHour(show.purchaseDate)}</td>
                            <td>{formatDateHour(show.date)}</td>
                            <td className="center-between">
                                <span onClick={() => { dispatch(setPopupPay(show)); dispatch(setPopup('support')) }} className="material-symbols-outlined clickable">support_agent</span>
                                {show.cancelled && <span style={{color:'red'}} onClick={() => dispatch(setPopup('cancel'))} className="material-symbols-outlined clickable">event_busy</span>}
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>}
        </section>
    }
    catch (err) {
        return <Error />
    }
}