import { useState, useRef } from "react"
import { useDispatch } from "react-redux"
import { setPopup, setUpperPopup } from "../../store/actions/general.actions.js"
import { setPersonal } from '../../store/reducers/userReducer'
import { userService } from "../../services/userService.js"

const Interests = ["טניס שולחן", "פוקר", "איגרוף", "כדורסל", "כדורגל", "שירה", "רוק", "טניס", "משחקי מחשב", "סרטים/סדרות", "LOL", "פאבים", "תכנות", "צילום", "חוף הים", "ספרים"]

export function PopupDetails() {
    const dispatch = useDispatch()
    const [gender, setGender] = useState('')
    const [interests, setInterests] = useState([])
    const yearChosen = useRef()
    const years = useRef(getYears())

    const handleInterests = (interest) => {
        if(interests.length === 5 && !interests.includes(interest)) return dispatch(setUpperPopup('max-interests'))
        if (interests.includes(interest)) {
            const idx = interests.indexOf(interest)
            const newInterests = [...interests]
            newInterests.splice(idx, 1)
            setInterests(newInterests)
        }
        else {
            const newInterests = [...interests, interest]
            setInterests(newInterests)
        }
    }

    const submitDetails = async () => {
        if (gender === '' || yearChosen.current.value === 'choose') {
            dispatch(setUpperPopup('missing-details'))
            return
        }
        try {
            const updatedDetails = await userService.submitDetails({ gender, year: yearChosen.current.value, interests })
            dispatch(setPersonal(updatedDetails))
            dispatch(setPopup(''))
        }
        catch (err) {
            console.log(err)
        }
    }

    return <section className="simple-popup">
        <section className="popup-details">
            <h3>אנחנו רוצים להכיר אותך, אנא מלא את השאלון הקצר:</h3>
            <p>מין :</p>
            <div className="gender-wrapper">
                <div className="gender-line" onClick={() => setGender('male')}>
                    <div>{gender === 'male' && <span className="material-symbols-outlined">done</span>}</div>
                    <p>זכר</p>
                </div>
                <div className="gender-line" onClick={() => setGender('female')}>
                    <div>{gender === 'female' && <span className="material-symbols-outlined">done</span>}</div>
                    <p>נקבה</p>
                </div>
                <div className="gender-line" onClick={() => setGender('other')}>
                    <div>{gender === 'other' && <span className="material-symbols-outlined">done</span>}</div>
                    <p>אחר</p>
                </div>
            </div>
            <p>תאריך לידה :</p>
            <select ref={yearChosen}>
                <option value='choose'>בחר</option>
                {years.current.map((year, idx) => <option key={idx} value={year}>{year}</option>)}
            </select>
            <p>תחביבים :</p>
            <div className="interests-wrapper">
                {Interests.map((interest, idx) => <div className={interests.includes(interest) ? 'highlighted clickable' : 'clickable'} key={idx} onClick={() => { handleInterests(interest) }}>{interest}</div>)}
            </div>
            <div className="buttons-wrapper" style={{marginTop:'10px'}}>
                <div className="lighter" style={{borderWidth:0}} onClick={() => dispatch(setPopup(''))}>דלג</div>
                <div className="bolder" onClick={submitDetails}>שלח</div>
            </div>
        </section>
    </section>
}

function getYears() {
    const years = []
    for (let i = Number(new Date().getFullYear()); i > 1910; i--) years.push(i)
    return years
}