import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { setPopup } from "../../store/actions/general.actions"

export function PopupVideo({ video }) {
    const dispatch = useDispatch()
    const [fullscreen, setFullscreen] = useState(false)

    useEffect(() => {
        document.documentElement.style.setProperty('--visibility', 'hidden')
        return () => document.documentElement.style.setProperty('--visibility', 'visible')
    }, [])

    return <div className={`popup-video ${fullscreen ? 'full-screen-teaser' : ''} `}>
        {window.innerWidth < 700 && <div className="full-screen-button">
            {fullscreen ? <span onClick={() => setFullscreen(false)} className="material-symbols-outlined"> fullscreen_exit</span>
                : <span onClick={() => setFullscreen(true)} className="material-symbols-outlined">fullscreen</span>}
        </div>}
        <iframe
            src={video.slice(0, 4) + 's' + video.slice(4)}
            width="100%"
            height="100%"
            allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
            allowfullscreen
            frameborder="0"
        />
        <div onClick={() => dispatch(setPopup(''))} className="popup-close-mobile"><p>לחץ לסגירה</p></div>
    </div>
}
