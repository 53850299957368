import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import { setPopup, setPopupPay } from "../store/actions/general.actions"
import { getDateName, formatHour, makeCommas } from "../services/utils"
import Timer from "./timer"

export function ShowBox({ show, user }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isVisable, setIsVisable] = useState(true)

    const buyTickets = async () => {
        if (user && user.phone) {
            dispatch(setPopupPay(show))
            dispatch(setPopup('pay'))
        }
        else dispatch(setPopup('login'))
    }

    const disappear = () => {
        setIsVisable(false)
    }

    if (!isVisable) return <></>

    return <div style={{ position: 'relative' }} >
        <div className='event-box-fund' >
            <section className="event-details" >
                <div className="name-img" onClick={() => navigate(`/creator/${show.miniCreator.id}`)}>
                    <img className="event-img" src={show.miniCreator.image} alt='player' />
                    <p>{show.miniCreator.nickName}</p>
                </div>
                <div className="timer-holder">
                    <p>מתחיל בעוד :</p>
                    <Timer showDate={new Date(show.date)} onEndStream={disappear} />
                </div>
                <div className="pricepool-holder">
                    <div>
                        <img className="watchers" alt='sold tickets' src={require('../style/imgs/stream/viewers.webp')} />
                        <div className="date-holder">
                            <p className="total-pricepool">{makeCommas(show.ticketsAmount)}</p>
                        </div>
                    </div>
                    <div>
                        <img className="sand-watch" src={require('../style/imgs/sand-watch.webp')} />
                        <div className="date-holder">
                            <p>{getDateName(show.date, 'he')}</p>
                            <p>{formatHour(show.date)}</p>
                        </div>
                    </div>
                </div>
            </section>
            <img className="thumbnail" src={show.img} />
            <section className="player-description">
                <h1>{show.title}</h1>
                <p>{show.shortDescription} {show.link && <span> בקר בדף <a target="_blank" rel="nofollow" href={show.link}>כאן</a>.</span>}</p>
            </section>
            <div className="support" onSubmit={buyTickets} id={show._id}>
                {user.purchases[show._id] ? <button className="bought" style={{ cursor: 'default' }}>נרכש</button> :
                    <button onClick={buyTickets}>קנה {show.price}₪</button>}
            </div>
        </div>
    </div>
}