import { useState, useEffect } from 'react'
import { make2digits } from '../services/utils'

export default function Timer(props) {
    useEffect(() => {
        return () => { clearTimeout(timeOut) }
    }, [])
    const [now, setNow] = useState(Date.now())
    const timeOut = setTimeout(() => {
        setNow(Date.now)
    }, 1000)

    const timestamp = props.showDate.getTime() - now

    let days = Math.floor(timestamp / (1000 * 60 * 60 * 24))
    let hours = Math.floor((timestamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    let minutes = Math.floor((timestamp % (1000 * 60 * 60)) / (1000 * 60))
    let seconds = Math.floor((timestamp % (1000 * 60)) / 1000)

    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
        clearTimeout(timeOut)
        if (props.onEndStream) props.onEndStream(true)
        if (props.mode === 'card') return <div className={props.glow ? 'watch-wrapper' : 'watch-wrapper cancel-animation'}><span className="material-symbols-outlined">smart_display</span><span>צפו עכשיו</span></div>
        return <h5></h5>
    }

    return (
        <div className='timer ltr'>
            {days > 0 && <span>{make2digits(days)} :</span>}
            {(hours > 0 || days > 0) && <span>{make2digits(hours)} :</span>}
            <span>{make2digits(minutes)} :</span>
            <span>{make2digits(seconds)}</span>
        </div>
    )
}

