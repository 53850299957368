import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getkHowLongAgo, putKandM } from '../../services/utils.js'
import { userService } from '../../services/userService.js'
import { setUpperPopup } from '../../store/actions/general.actions.js'

export function Comment({ comment, user }) {
    const dispatch = useDispatch()
    const [likesAmount, setLikesAmount] = useState(comment.likesAmount)
    const [userLiked, setUserLiked] = useState(comment.likedByUser)

    const [from, setFrom] = useState(0)
    const [replies, setReplies] = useState([])
    const [isReplying, setIsReplying] = useState(false)
    const [showReplies, setShowReplies] = useState(comment.repliesAmount > 0)

    const addReply = async (ev) => {
        ev.preventDefault()
        try {
            const text = ev.target[0].value
            if (!text) return
            const reply = {
                text,
                nickName: user.nickName,
                image: user.image,
            }
            const r = await userService.reply(comment._id, reply)
            setReplies([r, ...replies])
            setShowReplies(true)
            ev.target[0].value = ''
            setIsReplying(false)
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const handleLike = async () => {
        try {
            const newState = await userService.handleLikeComment(comment._id, !userLiked)
            setLikesAmount(newState ? likesAmount + 1 : likesAmount - 1)
            setUserLiked(newState)
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const loadReplies = async () => {
        try {
            const r = await userService.getReplies(comment._id, from)
            if (r.length < 10) setShowReplies(false)
            else setFrom(from + 10)
            setReplies(replies.concat(r))
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    return <div className="comment">
        <img src={comment.image} className="profile-mini" />
        <div style={{ flex: '1' }}>
            <div className='name'>
                <p>{comment.nickName}</p>
                <span>{getkHowLongAgo(comment.date)}</span>
            </div>
            <p>{comment.text}</p>
            <div className='lower-comment'>
                <span onClick={handleLike} style={{ fontVariationSettings: userLiked ? "'FILL' 1" : '' }} className="material-symbols-outlined test">thumb_up_alt</span>
                <p>{putKandM(likesAmount)}</p>
                <p onClick={() => setIsReplying(!isReplying)} className='reply'>{isReplying ? 'סגור' : 'הגב'}</p>
            </div>
            {isReplying && <form className="add-comment" onSubmit={addReply}>
                <img src={user.image} className="profile-mini" />
                <input type="text" placeholder="השב לתגובה" />
                <button><span className="material-symbols-outlined">send</span></button>
            </form>}
            {replies.length ? <div className="replies">
                {replies.map((r, idx) => <Reply reply={r} key={idx} />)}
            </div> : null}
            {(showReplies && replies.length < comment.repliesAmount) ? <div className='show-replies'>
                <span className="material-symbols-outlined">keyboard_arrow_down</span>
                <p onClick={loadReplies}>הצג עוד</p>
            </div> : null}
        </div>
    </div>
}

function Reply({ reply }) {
    return <div className="comment">
        <img src={reply.image} className="profile-mini" />
        <div>
            <p className='name'>{reply.nickName} <span>{getkHowLongAgo(reply.date)}</span></p>
            <p>{reply.text}</p>
        </div>
    </div>
}


