import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setPopupUpcoming,setPopup } from "../store/actions/general.actions"

export function PopupUpcoming() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { popupUpcoming } = useSelector((storeState) => storeState.generalModule)

    if (!popupUpcoming || window.location.href.includes('tickets')) return <></>

    const isMobile = window.innerWidth < 700
    return (
        <div className={`popup-upcoming ${isMobile? 'p-bottom' : 'p-top'}`}>
            <p className="text">אחת מההופעות שלך עומדת להתחיל!</p>
            <div className="nav-wrapper">
                <p className="close" onClick={() => dispatch(setPopupUpcoming(false))}>אוקיי</p>
                <p className='action' onClick={() => { navigate('/tickets'); dispatch(setPopupUpcoming(false));dispatch(setPopup('')) }}>צפה</p>
            </div>
        </div>
    )
}