import { useEffect, useRef, useState } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { userService } from "../../services/userService"
import { setUpperPopup } from "../../store/actions/general.actions.js"
import { Error } from '../../pages/error.jsx'
import { Post } from "./post.jsx"

export function Feed({ creatorId }) {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const [feed, setFeed] = useState([])
    const [from, setFrom] = useState(0)
    const [loader, setLoader] = useState(true)
    const [scrollToTop, setScrollToTop] = useState(false)
    const targetRef = useRef(null)
    const debounce = useRef(false)
    const debounce2 = useRef(false)

    useEffect(() => {
        window.addEventListener('scroll', loadMore)
        return () => window.removeEventListener('scroll', loadMore)
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', handleScrollTopButton)
    }, [])

    useEffect(() => {
        loadFeed(from)
    }, [from])

    const handleScrollTopButton = () => {
        if (debounce2.current) return
        if (window.scrollY > 1000) setScrollToTop(true)
        else setScrollToTop(false)
        debounce2.current = true
        setTimeout(() => { debounce2.current = false }, 1000)
    }

    const loadFeed = async () => {
        try {
            const f = await userService.getCreatorFeed(creatorId, from)
            setFeed(feed.concat(f))
            if (f.length < 10) setLoader(false)
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
            setLoader('error')
        }
    }

    const loadMore = () => {
        if (debounce.current) return
        if (!targetRef.current) {
            window.removeEventListener('scroll', loadMore)
            return
        }
        const rect = targetRef.current.getBoundingClientRect()
        if (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
            debounce.current = true
            setTimeout(() => { debounce.current = false }, 800)
            setFrom((from) => from + 10)
        }
    }

    if(loader === 'error') return <Error />

    try {
        return <div className="feed">
            {feed.map((item, idx) => <Post user={user} post={item} key={idx} />)}
            {scrollToTop && <div className="scroll-top" onClick={() => { window.scrollTo(0, 0); setTimeout(() => setScrollToTop(false), 1000) }}><span className="material-symbols-outlined">arrow_upward</span></div>}
            {loader && <div style={{ margin: 'auto', position: 'relative' }}><div ref={targetRef} className="loader loader-block"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>}
        </div>
    }
    catch {
        return <Error />
    }
}