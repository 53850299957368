import { useDispatch } from 'react-redux'
import { setPopup } from '../../store/actions/general.actions'

export function PopupInfo({ show }) {
    const dispatch = useDispatch()
    
    return <div className="bought">
        <h1>{show.title}</h1>
        <p>{show.longDescription}</p>
        <div className="done" onClick={() => dispatch(setPopup(''))}>אוקיי</div>
    </div>
}