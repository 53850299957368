import { setPopupCallback } from "../actions/general.actions"

const INITIAL_STATE = {
    filter: '',
    viewers: 0,
    menu: '',
    upperPopup: '',
    popup: '',
    popupInfo: [],
    popupPay: null,
    setPopupCallback: null,
    popupUpcoming: false,
    flashUpcoming: false,
    callbackLink: '',
    animations: true,
}

export function generalReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'SET_FILTER':
            return { ...state, filter: action.filter }
        case 'SET_VIEWERS':
            return { ...state, viewers: action.viewers }
        case 'SET_MENU':
            return { ...state, menu: action.menu }
        case 'SET_POPUP':
            return { ...state, popup: action.popup }
        case 'SET_POPUP_INFO':
            return { ...state, popupInfo: action.popupInfo }
        case 'SET_POPUP_PAY':
            return { ...state, popupPay: action.popupPay }
        case 'TOGGLE_MENU':
            if (state.menu === '') return { ...state, menu: 'normal' }
            else return { ...state, menu: '' }
        case 'SET_UPPER_POPUP':
            return { ...state, upperPopup: action.upperPopup }
        case 'SET_FLASH_UPCOMING':
            return { ...state, flashUpcoming: action.flashUpcoming }
        case 'SET_POPUP_UPCOMING':
            return { ...state, popupUpcoming: action.popupUpcoming }
        case 'SET_POPUP_CALLBACK':
            return { ...state, popupCallback: action.popupCallback }
        case 'SET_ANIMATIONS':
            return { ...state, animations: action.animations }
        case 'TOGGLE_ANIMATIONS':
            return { ...state, animations: !state.animations }
        case 'SET_CALLBACK_LINK':
            return { ...state, callbackLink: action.callbackLink }
        case 'RESET_GENERAL_STATE':
            return {
                filter: '',
                viewers: 0,
                menu: '',
                menuSide: 'right',
                upperPopup: '',
                popup: '',
                popupInfo: [],
                popupCallback: null,
                popupPay: null,
                callbackLink: '',
                animations: true,
            }
        default:
            return state;
    }
}