export function ShowBoxLoader() {

    return (
            <div className='event-box-loader' >
                <section className="event-details">
                    <div className="img-blank" />
                    <div className="timer-holder">
                        <p>טוען...</p>
                        <div className="timer"></div>
                    </div>
                    <div className="details-blank" />
                </section>

                <section className="event-distribution">
                    <h2>...</h2>
                    <div className="bar-wrapper">
                        <div className="blank-percent" />
                        <div className="distribution-bar">
                            <div className="blank-team-bar"></div>
                        </div>
                        <div className="blank-percent" />
                    </div>
                </section>

                <section className="event-teams">
                    <div className='team-1-holder'>
                        <div className="team-icon" />
                        <div className="blank-desc" />
                    </div>
                    <div className='team-2-holder'>
                        <div className="blank-desc" />
                        <div className="team-icon" />
                    </div>
                </section>
            </div>
    )
}