import { useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { userService } from "../services/userService"
import { setMenu, setUpperPopup, setPopup } from "../store/actions/general.actions"
import { setEmail } from "../store/reducers/userReducer"

export function Footer({ user }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const emailRef = useRef()
    const footer = !location.pathname.includes('/tickets')


    const onRegisterEmail = async (ev) => {
        try {
            ev.preventDefault()
            console.log(user)
            if (!user.phone) return navigate('/login')
            const email = emailRef.current.value
            await userService.updateInfo(user.phone, { email })
            dispatch(setEmail(email))
            dispatch(setUpperPopup('email-registered'))
        }
        catch {
            dispatch(setUpperPopup('errorServer'))
        }
    }

    if (!footer) return <></>

    try {
        return <div className='footer'>
        <p onClick={() => window.open('https://www.privacypolicyonline.com/live.php?token=BSvr42ubTJcEKnEu7WdkM3qMJ7Dozlla', '_blank').focus()}>תנאי שימוש</p>
        <p onClick={() => window.open('https://www.privacypolicyonline.com/live.php?token=J4MpfGXa6se1Ak9047TKVsyJzqtXRHjD', '_blank').focus()}>מדיניות פרטיות</p>
        <p onClick={() => window.open('https://homericentertainment.com/', '_blank').focus()}>הומריק בידור בע"מ®</p>
        <section className="contact">
            <p>הצטרפו לקהילה של PikmeTV :</p>
            <a href="https://www.instagram.com/pikme.tv/" target="_blank"><img src={require('../style/imgs/contact/instagram.webp')} /></a>
            <a href="https://twitter.com/PikmeTv" target="_blank"><img src={require('../style/imgs/contact/twitter.webp')} /></a>
            <a href="https://www.tiktok.com/@pikme.tv?lang=he-IL" target="_blank"><img src={require('../style/imgs/contact/tiktok.webp')} /></a>
        </section>
    </div>
    }
    catch (err) {
        console.log(err)
        return <></>
    }
}


// import { useLocation } from "react-router-dom"

// export function Footer() {
//     const location = useLocation()

//     if (location.pathname.includes('stream-control')) return <></>

//     return <div className='footer'>
//         <p onClick={() => window.open('https://homericentertainment.com/', '_blank').focus()}>®2024 Homeric entertainment</p>
//         <p onClick={() => window.open('https://www.privacypolicyonline.com/live.php?token=J4MpfGXa6se1Ak9047TKVsyJzqtXRHjD', '_blank').focus()}>privacy policy</p>
//         <p onClick={() => window.open('https://www.privacypolicyonline.com/live.php?token=BSvr42ubTJcEKnEu7WdkM3qMJ7Dozlla', '_blank').focus()}>terms of service</p>
//         <section className="contact">
//             <p>join PikmeTV community :</p>
//             <a href="https://www.instagram.com/pikme.tv/" target="_blank"><img src={require('../style/imgs/contact/instagram.webp')} /></a>
//             <a href="https://twitter.com/PikmeTv" target="_blank"><img src={require('../style/imgs/contact/twitter.webp')} /></a>
//             <a href="https://www.tiktok.com/@pikme.tv?lang=he-IL" target="_blank"><img src={require('../style/imgs/contact/tiktok.webp')} /></a>
//         </section>
//     </div>

// }
