import { httpService } from './http.service.js'

export const showService = {
    getById,
    makeOrder,
}
async function getById(showId) {
    const show = await httpService.get(`handle-show/get-show/${showId}`)
    return show
}

async function makeOrder(id) {
    const orderData = await httpService.post('handle-show/create-order', { id })
    return orderData
}
