import { useState } from "react"
import { CreatorCard } from "./creator-card"
import { ShowCard } from "./show-card"

export function Discover({ discover }) {

    const [type, setType] = useState('creators')

    if (!discover || !discover.creators || !discover.shows) return <div className="loader"><div></div><div></div><div></div><div></div>
        <div></div><div></div><div></div><div></div></div>

    const data = discover[type]

    return <div className="discover-wrapper">
        <div className="discover-choose">
            <p className={type === 'creators' ? 'chosen' : ''} onClick={() => setType('creators')}>יוצרי תוכן</p>
            <p className={type === 'shows' ? 'chosen' : ''} onClick={() => setType('shows')}>הופעות</p>
        </div>
        {data.length ? <div className='discover'>
            {data.map((item) => type === 'creators' ? <div className='creator-card-wrapper' key={item._id}><CreatorCard creator={item} /></div> :
                <div className='creator-card-wrapper' key={item._id}><ShowCard show={item} key={item._id} /></div>)}
        </div> :
            <div className="not-found">
                <img className="no-history" src={require('../style/imgs/no-results.webp')} alt='no results' />
                <p>לא נמצאו תוצאות בינתיים...</p>
            </div>}

    </div>
}