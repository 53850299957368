const INITIAL_STATE = {
    live: {
        isDone: false,
        phase: 0,
        y: 0,
        event: 'vs',
        eventId: ''
    },
    profile: {
        isDone: false,
        phase: 0,
    },
    history: {
        isDone: false,
        phase: 0,
    },
    tStats: {
        isDone: false,
        phase: 0,
    },
    tStream: {
        isDone: false,
        phase: 0,
    }
}

export function tutorialReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'SET_LIVE_PHASE':
            return { ...state, live: { ...state.live, phase: action.phase, isDone: action.phase > 3 ? true : false } }
        case 'SET_LIVE_Y':
            return { ...state, live: { ...state.live, y: action.y } }
        case 'SET_LIVE_EVENT_ID':
            return { ...state, live: { ...state.live, eventId: action.eventId } }
        case 'SET_LIVE_EVENT':
            return { ...state, live: { ...state.live, event: action.event } }
        case 'SET_PROFILE_PHASE':
            return { ...state, profile: { ...state.profile, phase: action.phase, isDone: action.phase > 2 ? true : false } }
        case 'SET_HISTORY_PHASE':
            return { ...state, history: { ...state.history, phase: action.phase, isDone: action.phase > 1 ? true : false } }
        case 'SET_STATS_PHASE':
            return { ...state, tStats: { ...state.tStats, phase: action.phase, isDone: action.phase > 3 ? true : false } }
        case 'SET_STATS_POSITION':
            return { ...state, tStats: { ...state.tStats, x: action.x, y: action.y } }
        case 'SET_STREAM_PHASE':
            return { ...state, tStream: { ...state.tStream, phase: action.phase, isDone: action.phase > 4 ? true : false } }
        case 'RESET_TUTORIAL_STATE':
            return INITIAL_STATE
        case '':
        default:
            return state
    }
}